import { useEffect, useState } from "react";
import axios from 'axios';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link, useNavigate } from "react-router-dom";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Header from "./Header";
import Footer from "./Footer";
import PreLoader from "./PreLoader";
import { baseUrl, imgUrl } from "./Constant";
import VectorImg from './assets/img/vectorImg.png';
import LivingImg from './assets/img/livingImg.png';
const Home = () => {
    const [homeBanner, setHomeBanner] = useState({});
    const [homeBlogBtm, setHomeBlogBtm] = useState('');
    const [homeBlogMid, setHomeBlogMid] = useState('');
    const [homeProductCat, setHomeProductCat] = useState({});
    const [homeShopCat, setHomeShopCat] = useState({});
    const [trueStory, setTrueStory] = useState({});
    const [trustedPartner, setTrustedPartner] = useState({});
    const [trustedPartnerList, setTrustedPartnerList] = useState([]);
    const [homeProductCatList, setHomeProductCatList] = useState([]);
    const [homeBlogBtmList, setHomeBlogBtmList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [email, setEmail] = useState('')
    const [successMsg, setSuccessMsg] = useState({})
    const [pluginCodeOneHTML, setPluginCodeOneHTML] = useState('')
    const [pluginCodeTwoHTML, setPluginCodeTwoHTML] = useState('')
    const [homeProdShop, setHomeProdShop] = useState([])
    const [selectedTab, setSelectedTab] = useState(0);
    const [quote, setQuote] = useState('');
    const navigate = useNavigate()

    useEffect(() => {
        GetHomePage()
        GetHomeProduct()
        localStorage.removeItem('item')
    }, []);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://iplfntl-zc1.maillist-manage.in/js/optin.min.js';
        script.onload = () => {
            window.setupSF('sf3z9bacabf651561afe92c45116e55f476ed1fe429d5606965389c63586ae5872ac', 'ZCFORMVIEW', false, 'light', false, '0');
        };
        document.body.appendChild(script);
        return () => {
            const zohoForm = document.getElementById('sf3z9bacabf651561afe92c45116e55f476ed1fe429d5606965389c63586ae5872ac');
            if (zohoForm && zohoForm.parentNode) {
                zohoForm.parentNode.removeChild(zohoForm);
            }
        };
    }, []);

    const GetHomePage = () => {
        axios.get(baseUrl + `get-home-page-list`)
        // axios.get(baseUrl + `get-home-page`)
            .then((resp) => {
                if (resp.status === 200) {
                    // console.log('Result -------------- ', resp.data.result.home_page_shopping_category)
                    setHomeBanner(resp.data.result.home_page)
                    setHomeBlogBtm(resp.data.result.home_page_blog_bottom)
                    setHomeBlogMid(resp.data.result.home_page_blog_middle)
                    setHomeProductCat(resp.data.result.home_page_shop_product_category)
                    setHomeShopCat(resp.data.result.home_page_shopping_category)
                    setTrueStory(resp.data.result.true_story)
                    setTrustedPartner(resp.data.result.trusted_partner)
                    setTrustedPartnerList(resp.data.result.trusted_partner.trusted_partner_image)
                    setHomeProductCatList(resp.data.result.home_page_shop_product_category.home_page_shop_product)
                    setHomeBlogBtmList(resp.data.result.home_page_blog_bottom.home_page_blog)
                    setQuote(resp.data.result.home_page_blog_bottom.home_page_quote)

                    const pluginCodeOne = { ...resp.data.result.home_page_blog_middle.blog_1 }.blog_1_shop_store_code.split('<script type="text/javascript">');
                    const pluginCodeOneScript = pluginCodeOne[1].split('</script>');
                    const script = document.createElement('script');
                    script.type = 'text/javascript';
                    script.innerHTML = pluginCodeOneScript[0];
                    script.async = true;
                    document.head.appendChild(script);
                    setPluginCodeOneHTML(pluginCodeOne[0] + '</div>')

                    const pluginCodeTwo = { ...resp.data.result.home_page_blog_middle.blog_2 }.blog_2_shop_store_code.split('<script type="text/javascript">');
                    const pluginCodeTwoScript = pluginCodeTwo[1].split('</script>');
                    script.innerHTML = pluginCodeTwoScript[0];
                    setPluginCodeTwoHTML(pluginCodeTwo[0] + '</div>')
                    
                    setLoading(false)
                }
            })
            .catch((err) => {
                if (err.request && err.request.status === 401) {
                    console.log(err);
                }
            });
    }
    const GetHomeProduct = () => {
        axios.post(baseUrl + `get-home-page-product`)
            .then((resp) => {
                if (resp.status === 200) {
                    setHomeProdShop(resp.data.result)
                }
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    console.log(err)
                }
            })
    }
    const handleTabSelect = (key) => {
        setSelectedTab(key);
    };
    const handleClick = (placeToGo) => {
        navigate(placeToGo, { replace: true });
        window.location.reload();
        window.scrollTo(0, 0)
    }
 
    return (
        <>
            {loading && <PreLoader />}
            {!loading &&
                <>
                    <Header />
                    <div className="mainContent">
                        <div className="homeBannerWrap">
                            <div className="container-fluid bigScreenWid">
                                <div className="row">
                                    <div className="col-md-3 padrt0 posRel">
                                        <div className="dataWrap">
                                            <h2>{homeBanner.blog_title}</h2>
                                            <div className="descripWrap">
                                                <p dangerouslySetInnerHTML={{ __html: homeBanner.blog_short_description }}></p>
                                            </div>
                                            <Link className="btnRead" to={`BlogDetails/${homeBanner.slug_url}`} onClick={() => handleClick(`BlogDetails/${homeBanner.slug_url}`)}>Read More</Link>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-8 col-8 pad0">
                                        <div className="imgWrap">
                                            <img src={`${imgUrl}${homeBanner.image_path_1}`} className="img-fluid" />
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-4 col-4 padlt0 posRel">
                                        <div className="rightImgWrap">
                                            <img src={`${imgUrl}${homeBanner.image_path_2}`} className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="partnersWrap">
                            <div className="container-fluid bigScreenWid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="titleWrap">
                                            <h2>{trustedPartner.trusted_partner_text}</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <ul className="partnersListing">
                                            {trustedPartnerList.map((imgs) => (
                                                <li key={imgs.id}>
                                                    <div className="imgWrap">
                                                        <Link to={imgs.link} target="_blank">
                                                            <img src={`${imgUrl}${imgs.image_path}`} alt={imgs.caption} className="img-fluid" />
                                                        </Link>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-7 padRightInc">
                                        <div className="itemWrap">
                                            <Link to={`BlogShopCategory/${{ ...homeShopCat.shopping_category_1 }.shopping_category_id_1}`} onClick={() => handleClick(`BlogShopCategory/${{ ...homeShopCat.shopping_category_1 }.shopping_category_id_1}`)}>
                                                <div className="imgWrap">
                                                    <img src={`${imgUrl}${{ ...homeShopCat.shopping_category_1 }.shopping_category_image_path_1}`} className="img-fluid" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>{{ ...homeShopCat.shopping_category_1 }.shopping_category_id_1_title}</h2>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-md-5 padLeft0">
                                        <div className="itemWrap marBtm imgRightWrap">
                                            <Link to={`BlogShopCategory/${{ ...homeShopCat.shopping_category_2 }.shopping_category_id_2}`} onClick={() => handleClick(`BlogShopCategory/${{ ...homeShopCat.shopping_category_2 }.shopping_category_id_2}`)}>
                                                <div className="imgWrap">
                                                    <img src={`${imgUrl}${{ ...homeShopCat.shopping_category_2 }.shopping_category_image_path_2}`} className="img-fluid" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>{{ ...homeShopCat.shopping_category_2 }.shopping_category_id_2_title}</h2>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="itemWrap imgRightWrap marBtm">
                                            <Link to={`BlogShopCategory/${{ ...homeShopCat.shopping_category_3 }.shopping_category_id_3}`} onClick={() => handleClick(`BlogShopCategory/${{ ...homeShopCat.shopping_category_3 }.shopping_category_id_3}`)}>
                                                <div className="imgWrap">
                                                    <img src={`${imgUrl}${{ ...homeShopCat.shopping_category_3 }.shopping_category_image_path_3}`} className="img-fluid" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>{{ ...homeShopCat.shopping_category_3 }.shopping_category_id_3_title}</h2>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="itemWrap imgRightWrap">
                                            <Link to={`BlogShopCategory/${{ ...homeShopCat.shopping_category_4 }.shopping_category_id_4}`} onClick={() => handleClick(`BlogShopCategory/${{ ...homeShopCat.shopping_category_4 }.shopping_category_id_4}`)}>
                                                <div className="imgWrap">
                                                    <img src={`${imgUrl}${{ ...homeShopCat.shopping_category_4 }.shopping_category_image_path_4}`} className="img-fluid" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>{{ ...homeShopCat.shopping_category_4 }.shopping_category_id_4_title}</h2>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="fashionWrap">
                            <div className="container-fluid bigScreenWid">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="imagesWrap">
                                            <div className="topImg">
                                                <img src={`${imgUrl}${{ ...homeBlogMid.blog_1 }.blog_1_image_path_1}`} className="img-fluid" />
                                            </div>
                                            <div className="btmImg">
                                                <img src={`${imgUrl}${{ ...homeBlogMid.blog_1 }.blog_1_image_path_2}`} className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 padlt0">
                                        <div className="fasionContent">
                                            <span>{{ ...homeBlogMid.blog_1 }.blog_1_shopping_category_id_title}</span>
                                            <h2>{{ ...homeBlogMid.blog_1 }.blog_id_1_title}</h2>
                                            <p dangerouslySetInnerHTML={{ __html: { ...homeBlogMid.blog_1 }.blog_id_1_short_description} }></p>
                                            <Link className="btnRead" to={`BlogDetails/${{ ...homeBlogMid.blog_1 }.blog_id_1_slug_url}`} onClick={() => handleClick(`BlogDetails/${{ ...homeBlogMid.blog_1 }.blog_id_1_slug_url}`)}>Read More</Link>
                                        </div>
                                        <div className="instaFeeds">
                                            <h3>
                                                <span>{{ ...homeBlogMid.blog_1 }.blog_1_shop_store_title_1} </span>
                                                {{ ...homeBlogMid.blog_1 }.blog_1_shop_store_title_2} </h3>

                                            <ul className="instaFeedSlidr">
                                                <li>
                                                    <div dangerouslySetInnerHTML={{ __html: pluginCodeOneHTML }}></div>
                                                </li>
                                            </ul>
                                            <p>FOLLOW ME ON INSTAGRAM : <a href="https://www.instagram.com/teacherfashionista/" target="_blank">@teacherfashionista</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="fashionWrap fashionWrapTwo">
                            <div className="container-fluid bigScreenWid">
                                <div className="row">
                                    <div className="col-md-6 padlt0">
                                        <div className="fasionContent">
                                            <span>{{ ...homeBlogMid.blog_2 }.blog_2_shopping_category_id_title}</span>
                                            <h2>{{ ...homeBlogMid.blog_2 }.blog_id_2_title}</h2>
                                            <p dangerouslySetInnerHTML={{ __html: { ...homeBlogMid.blog_2 }.blog_id_2_short_description} }></p>
                                            <Link className="btnRead" to={`BlogDetails/${{ ...homeBlogMid.blog_2 }.blog_id_2_slug_url}`} onClick={() => handleClick(`BlogDetails/${{ ...homeBlogMid.blog_2 }.blog_id_2_slug_url}`)}>Read More</Link>
                                        </div>
                                        <div className="instaFeeds">
                                            <h3><span>{{ ...homeBlogMid.blog_2 }.blog_2_shop_store_title_1}</span> {{ ...homeBlogMid.blog_2 }.blog_2_shop_store_title_2}</h3>
                                            <ul className="instaFeedSlidr">
                                                <li>
                                                    <div dangerouslySetInnerHTML={{ __html: pluginCodeTwoHTML }} ></div>
                                                </li>
                                            </ul>
                                            <p>FOLLOW ME ON INSTAGRAM : <a href="https://www.instagram.com/teacherfashionista/" target="_blank">@teacherfashionista</a></p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="imagesWrap">
                                            <div className="btmImg">
                                                <img src={`${imgUrl}${{ ...homeBlogMid.blog_2 }.blog_2_image_path_2}`} className="img-fluid" />
                                            </div>
                                            <div className="topImg">
                                                <img src={`${imgUrl}${{ ...homeBlogMid.blog_2 }.blog_2_image_path_1}`} className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="livingWrap">
                            <div className="container-fluid bigScreenWid">
                                <div className="row">
                                    <div className="col-md-12 pad0px">
                                        <div className="leftPanel">
                                            <div className="row justify-content-center">
                                                <div className="col-md-5">
                                                    <div className="topImgsWrap">
                                                        <div className="img1">
                                                            <img src={`${imgUrl}${{ ...homeBlogMid.blog_2 }.blog_2_image_path_1}`} className="img-fluid" />
                                                        </div>
                                                        <div className="img2">
                                                            <img src={`${imgUrl}${{ ...homeBlogMid.blog_2 }.blog_2_image_path_2}`} className="img-fluid" />
                                                        </div>
                                                    </div>
                                                    <div className="socialShareWrap">
                                                        <h4>LET’S BE SOCIAL  :</h4>
                                                        <ul>
                                                            <li><Link to={"https://www.instagram.com/teacherfashionista/"} target='_blank'><i className="fa-brands fa-instagram"></i></Link></li>
                                                            <li><Link to={"https://www.facebook.com/teacherfashionista"} target='_blank'><i className="fa-brands fa-facebook"></i></Link></li>
                                                            <li><Link to={"https://in.pinterest.com/teacherfashionista/"} target='_blank'><i className="fa-brands fa-pinterest"></i></Link></li>
                                                            <li>
                                                                <Link
                                                                    to={'https://www.shopltk.com/explore/teacherfashionista'}
                                                                    target='_blank'
                                                                >
                                                                    <svg width="16" height="16" version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110.000000 110.000000">

                                                                        <g transform="translate(0.000000,110.000000) scale(0.100000,-0.100000)"
                                                                            stroke="none">
                                                                            <path d="M440 1083 c-36 -6 -107 -31 -141 -48 -30 -16 -36 -37 -20 -77 5 -13 15 -45 22 -71 11 -43 13 -46 28 -33 35 32 130 65 197 70 l67 4 70 70 70 70 -52 11 c-50 11 -190 13 -241 4z" fill="#000000" />
                                                                            <path d="M750 965 l-63 -63 41 -21 c57 -29 108 -76 137 -126 23 -39 29 -42 97 -58 40 -10 83 -20 96 -23 33 -9 29 16 -18 113 -43 88 -123 180 -191 220 l-37 21 -62 -63z" fill="#000000" />
                                                                            <path d="M150 918 c-88 -101 -127 -191 -137 -309 l-6 -75 80 -22 c44 -12 82 -22 84 -22 2 0 5 33 5 73 2 58 8 87 34 143 l31 71 -25 88 c-14 48 -27 89 -28 91 -2 1 -19 -16 -38 -38z" fill="#000000" />
                                                                            <path d="M368 699 l-48 -41 0 -68 0 -69 112 -100 c61 -56 115 -101 120 -101 4 0 58 45 118 101 l110 101 0 68 0 68 -47 41 c-26 22 -51 41 -55 41 -4 0 -34 -26 -68 -57 -34 -31 -65 -52 -68 -47 -14 19 -111 104 -119 104 -4 -1 -29 -19 -55 -41z" fill="#000000" />
                                                                            <path d="M924 536 c0 -70 -5 -90 -33 -148 l-32 -67 22 -78 c12 -43 23 -84 25 -92 10 -34 112 97 152 197 24 61 49 210 36 221 -5 5 -45 17 -89 28 l-80 19 -1 -80z" fill="#000000" />
                                                                            <path d="M24 407 c10 -50 72 -168 115 -218 22 -25 65 -63 96 -84 l56 -39 64 64 64 63 -42 22 c-62 34 -114 81 -143 130 -21 36 -34 47 -67 55 -23 6 -55 15 -72 20 -78 22 -78 22 -71 -13z" fill="#000000" />
                                                                            <path d="M764 239 c-14 -23 -116 -59 -183 -65 l-69 -7 -68 -68 c-75 -76 -77 -72 33 -89 85 -14 187 -2 273 32 81 31 85 37 70 95 -28 108 -39 128 -56 102z" fill="#000000" />
                                                                        </g>
                                                                    </svg>
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-md-5">
                                                    <div className="topDataWrap">
                                                        <span>{{ ...homeBlogMid.blog_2 }.blog_2_shopping_category_id_title}</span>
                                                        <h2>{{ ...homeBlogMid.blog_2 }.blog_id_2_title}</h2>
                                                        <p dangerouslySetInnerHTML={{ __html: { ...homeBlogMid.blog_2 }.blog_id_2_description} }></p>
                                                        <Link className="btnRead" to={`BlogDetails/${{ ...homeBlogMid.blog_2 }.blog_id_2_slug_url}`} onClick={() => handleClick(`BlogDetails/${{ ...homeBlogMid.blog_2 }.blog_id_2_slug_url}`)}>Read More</Link>
                                                    </div>
                                                    <div className="americalEgalWrap">
                                                        <h3><span>{{ ...homeBlogMid.blog_2 }.blog_2_shop_store_title_1}</span> {{ ...homeBlogMid.blog_2 }.blog_2_shop_store_title_2}</h3>
                                                        <ul className="americalEgalSlider">
                                                            <li>
                                                                <div dangerouslySetInnerHTML={{ __html: pluginCodeTwoHTML }} ></div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {homeProdShop.length > 0 ? (
                            <div className="pickUpWrap">
                                <div className="container-fluid bigScreenWid">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="titleWrap">
                                                <h2>{homeProductCat.shop_title}</h2>
                                                <p>{homeProductCat.shop_text}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-md-10">
                                            <Tabs
                                                id="controlled-tab-example"
                                                onSelect={handleTabSelect}
                                                className="pickUpFilter"
                                            >
                                                {homeProductCatList.map((itemPick) => {
                                                    return (
                                                        <Tab eventKey={itemPick.title} title={itemPick.title}>
                                                            {homeProdShop.some((prodItem) => itemPick.shop_id === prodItem.shop_id || itemPick.product_category_id === prodItem.product_category_id) ? (
                                                                <Carousel
                                                                    additionalTransfrom={0}
                                                                    arrows
                                                                    autoPlaySpeed={3000}
                                                                    centerMode={false}
                                                                    className=""
                                                                    containerClass="container-with-dots"
                                                                    dotListClass=""
                                                                    draggable
                                                                    focusOnSelect={false}
                                                                    infinite
                                                                    itemClass=""
                                                                    keyBoardControl
                                                                    minimumTouchDrag={80}
                                                                    pauseOnHover
                                                                    renderArrowsWhenDisabled={false}
                                                                    renderButtonGroupOutside={false}
                                                                    renderDotsOutside={false}
                                                                    responsive={{
                                                                        desktop: {
                                                                            breakpoint: {
                                                                                max: 3000,
                                                                                min: 1024
                                                                            },
                                                                            items: 3,
                                                                        },
                                                                        mobile: {
                                                                            breakpoint: {
                                                                                max: 464,
                                                                                min: 0
                                                                            },
                                                                            items: 1,
                                                                        },
                                                                        tablet: {
                                                                            breakpoint: {
                                                                                max: 1024,
                                                                                min: 464
                                                                            },
                                                                            items: 2,
                                                                        }
                                                                    }}
                                                                    rewind={false}
                                                                    rewindWithAnimation={false}
                                                                    rtl={false}
                                                                    shouldResetAutoplay
                                                                    showDots={false}
                                                                    sliderClass=""
                                                                    slidesToSlide={1}
                                                                    swipeable
                                                                >
                                                                    {homeProdShop.map((prodItem) => {
                                                                        if (itemPick.shop_id === prodItem.shop_id || itemPick.product_category_id === prodItem.product_category_id)
                                                                            return (
                                                                                <div className="itemWrap" key={prodItem.id}>
                                                                                    <div className="imgWrap">
                                                                                        <Link to='/Shop' onClick={() => handleClick(`/Shop`)}>
                                                                                            <img src={`${imgUrl}${prodItem.image_path}`} alt={prodItem.title} className="img-fluid" />
                                                                                        </Link>
                                                                                    </div>
                                                                                    <div className="dataWrap">
                                                                                        <h3>{prodItem.title}</h3>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                    })}
                                                                </Carousel>
                                                            ) : (
                                                                <p className="noProduct">No products available.</p>
                                                            )}
                                                        </Tab>

                                                    )
                                                })}
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            null
                        )}
                        
                        <div className="trueStoryWrap">
                            <div className="container-fluid bigScreenWid">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="trueStoryCotnent">
                                            <span className="topVector"></span>
                                            <div className="row align-items-center">
                                                <div className="col-md-4">
                                                    <div className="imgWrap">
                                                        <img src={`${imgUrl}${trueStory.true_story_image_path}`} className="img-fluid" />
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="dataWrap">
                                                        <div className="leftData">
                                                            <span>{trueStory.true_story_title}</span>
                                                            <p dangerouslySetInnerHTML={{ __html: trueStory.true_story_description }}></p>
                                                            <h4>{trueStory.true_story_writer_name} <span>{trueStory.true_story_writer_designation}</span></h4>
                                                        </div>
                                                        <div className="rightImg">
                                                            <img src={VectorImg} className="img-fluid" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <span className="BtmVector"></span>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="oldNavyWrap">
                                            <div className="imgWrap">
                                                <img src={LivingImg} className="img-fluid" />
                                            </div>
                                            <div className="emailDataWrap">
                                                <div className="icoWrap">
                                                    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M19 -0.000244141H5C3.67441 0.00134372 2.40356 0.528638 1.46622 1.46598C0.528882 2.40331 0.00158786 3.67416 0 4.99976L0 16.9998C0.00158786 18.3254 0.528882 19.5962 1.46622 20.5335C2.40356 21.4709 3.67441 21.9982 5 21.9998H19C20.3256 21.9982 21.5964 21.4709 22.5338 20.5335C23.4711 19.5962 23.9984 18.3254 24 16.9998V4.99976C23.9984 3.67416 23.4711 2.40331 22.5338 1.46598C21.5964 0.528638 20.3256 0.00134372 19 -0.000244141ZM5 1.99976H19C19.5988 2.00093 20.1835 2.18127 20.679 2.51756C21.1744 2.85385 21.5579 3.3307 21.78 3.88676L14.122 11.5458C13.5584 12.1071 12.7954 12.4222 12 12.4222C11.2046 12.4222 10.4416 12.1071 9.878 11.5458L2.22 3.88676C2.44215 3.3307 2.82561 2.85385 3.32105 2.51756C3.81648 2.18127 4.40121 2.00093 5 1.99976ZM19 19.9998H5C4.20435 19.9998 3.44129 19.6837 2.87868 19.1211C2.31607 18.5585 2 17.7954 2 16.9998V6.49976L8.464 12.9598C9.40263 13.896 10.6743 14.4218 12 14.4218C13.3257 14.4218 14.5974 13.896 15.536 12.9598L22 6.49976V16.9998C22 17.7954 21.6839 18.5585 21.1213 19.1211C20.5587 19.6837 19.7956 19.9998 19 19.9998Z" fill="#333333" />
                                                    </svg>

                                                </div>
                                                <div className="dataWrap">
                                                    <h3>Get the latest from Teacherfashionista sent to your email.</h3>
                                                    {/* <form onSubmit={(e) => {
                                                        e.preventDefault()
                                                        let value = {
                                                            email: email,
                                                        }
                                                        axios.post(baseUrl + 'add-email-subscribe', value)
                                                            .then((resp) => {
                                                                if (resp.status === 200) {
                                                                    setEmail("")
                                                                    setSuccessMsg(resp.data)
                                                                    console.log('this is true', resp.data.msg)
                                                                }
                                                                else {
                                                                    console.log(resp)
                                                                    console.log('something went wrong....')
                                                                }
                                                            })
                                                            .catch((err) => {
                                                                console.log(err)
                                                            })
                                                    }}>
                                                        {successMsg['msg'] &&
                                                            <div class="alert alert-success mb-4" role="alert">
                                                                <strong>{successMsg['msg']}</strong>
                                                            </div>
                                                        }
                                                        <div className="inpuFieldWrap">
                                                            <input placeholder="Email Address" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                                            <button type="submit">Subscribe</button>
                                                        </div>
                                                    </form> */}
                                                    <iframe frameborder="0" id="iframewin" width="100%" height="100%" src="https://iplfntl-zc1.maillist-manage.in/ua/Optin?od=1a1e3db42dff0&zx=1df99926dd&tD=11f21c9d39612269&sD=11f21c9d3961c7c1" style={{margin:'-20px 0 0'}}></iframe>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="favoritesWrap">
                            <div className="container-fluid bigScreenWid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="titleWrap">
                                            <h2>Blogs</h2>
                                            <p>{homeBlogBtm.blog_text}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <ul>
                                            {homeBlogBtmList.slice(0, 3).map((blogItem) => {
                                                return (
                                                    <>
                                                        <li>
                                                            <div className="topImgWrap favItem">
                                                                <div className="imgWrap">
                                                                    <Link to={`BlogDetails/${blogItem.slug_url}`} onClick={() => handleClick(`BlogDetails/${blogItem.slug_url}`)}>
                                                                        <img src={`${imgUrl}${blogItem.thumbnail_path}`} className="img-fluid" />
                                                                    </Link>
                                                                </div>
                                                                <div className="imgData">
                                                                    {blogItem.blog_tag_name.map((blogTag) => {
                                                                        return (
                                                                            <span>{blogTag.title}</span>
                                                                        )
                                                                    })}
                                                                    <Link to={`BlogDetails/${blogItem.slug_url}`} onClick={() => handleClick(`BlogDetails/${blogItem.slug_url}`)}>
                                                                        <h3>{blogItem.title}</h3>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </>
                                                )
                                            })}
                                            <li className="quoteTxt">
                                                <div className="favDataDesc">
                                                    <p>{quote.quote_1}</p>
                                                </div>
                                            </li>
                                            <li className="bigWid">
                                                <div className="bgClrtxt">
                                                    <div className="btmDataWrap">
                                                        <h3>{quote.quote_2}</h3>
                                                    </div>
                                                </div>
                                            </li>
                                            {homeBlogBtmList.slice(3, homeBlogBtmList.length).map((blogItem) => {
                                                return (
                                                    <>
                                                        <li>
                                                            <div className="topImgWrap favItem">
                                                                <div className="imgWrap">
                                                                    <Link to={`BlogDetails/${blogItem.slug_url}`} onClick={() => handleClick(`BlogDetails/${blogItem.slug_url}`)}>
                                                                        <img src={`${imgUrl}${blogItem.thumbnail_path}`} className="img-fluid" />
                                                                    </Link>
                                                                </div>
                                                                <div className="imgData">
                                                                    {blogItem.blog_tag_name.map((blogTag) => {
                                                                        return (
                                                                            <span>{blogTag.title}</span>
                                                                        )
                                                                    })}
                                                                    <Link to={`BlogDetails/${blogItem.slug_url}`} onClick={() => handleClick(`BlogDetails/${blogItem.slug_url}`)}>
                                                                        <h3>{blogItem.title}</h3>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="ctasWrap">
                                            <Link to={`/LatestBlog`} className="ctaTxt">View More Blogs</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </>
            }

        </>
    );
}
export default Home;