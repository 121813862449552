import { useEffect, useState } from "react";
import axios from 'axios';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import PreLoader from "./PreLoader";
import Pagination from "./Pagination";
import { baseUrl, imgUrl } from "./Constant";
const BlogShopCategory = () => {
    const { shopping_category_id } = useParams()
    const navigate = useNavigate()
    const [blog, setBlog] = useState([])
    const [noBlogAvailable, setNoBlogAvailable] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalBlogs, setTotalBlogs] = useState(0);
    const [postsPerPage, setPostsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        GetBlogs()
    }, [])

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const GetBlogs = (pageNumber) => {
        let data = { shopping_category_id: shopping_category_id, page_no: pageNumber };
        // let url = baseUrl + `get-blog`;
        let url = baseUrl + `get-blog-list`;

        axios.post(url, data)
            .then((resp) => {
                if (resp.status === 200) {
                    const filteredData = resp.data.result.filter((post) => post.shopping_category_id === parseInt(shopping_category_id));
                    filteredData.forEach((post) => {
                        post.publish_date = formatDate(post.publish_date);
                    });

                    setBlog(filteredData);
                    setPostsPerPage(resp.data.current_page_size);
                    setTotalBlogs(resp.data.total_items);
                    setTotalPages(resp.data.total_pages);
                    setLoading(false);
                }
            }).catch((err) => {
                if (err.request.status === 401) {
                    console.log(err);
                }
            });
    };

    const sliceData = () => {
        const startIndex = (currentPage - 1) * postsPerPage;
        const endIndex = startIndex + postsPerPage;
        return blog.slice(startIndex, endIndex);
    };

    const handlePageChange = (pageNumber) => {
        setTotalPages(0);
        setCurrentPage(pageNumber);
        GetBlogs(pageNumber);
    };

    const handleClick = (placeToGo) => {
        navigate(placeToGo, { replace: true });
        window.location.reload();
        window.scrollTo(0, 0)
    }

    return (
        <>
            {loading && <PreLoader />}
            {!loading &&
                <>
                    <Header />
                    <div className="mainContent">
                        <div className="latestPostWrap subCatBlogWrap">
                            <div className="container-fluid bigScreenWid">
                                <div className="row">
                                    <div className="col-md-12">
                                        {blog.length === 0 ? (
                                            <div className="minHtNoBlog">
                                                <p className="noProduct">No blog available.</p>
                                            </div>
                                        ) : (
                                            <ul className="lPostListing">
                                                {blog.map((Val) => {
                                                    return (
                                                        <>
                                                            <li>
                                                                <div className="itemWrap">
                                                                    <Link to={`/BlogDetails/${Val.slug_url}`}>
                                                                        <div className="imgWrap">
                                                                            <img src={`${imgUrl}${Val.thumbnail_path}`} className="img-fluid" />
                                                                            <p className="dateTxt">{Val.publish_date}</p>
                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                                <div className="dataWrap">
                                                                    <span>{Val.shopping_category_title}</span>
                                                                    <Link to={`/BlogDetails/${Val.slug_url}`}>
                                                                        <h3>{Val.title}</h3>
                                                                    </Link>
                                                                    <div className="descripWrap">
                                                                        <p dangerouslySetInnerHTML={{ __html: Val.short_description }}></p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="paginationPgeWrap">
                            <Pagination
                                totalPages={totalPages}
                                currentPage={currentPage}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </div>
                    <Footer />
                </>
            }
        </>
    );
}
export default BlogShopCategory;