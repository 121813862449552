// export const baseUrl = 'https://admin.tfstaging.digitalgokart.com/api/'
// export const imgUrl = 'https://admin.tfstaging.digitalgokart.com/public/storage/'
// export const blogDtlUrl = 'https://tfstaging.digitalgokart.com/#/BlogDetails/'


export const baseUrl = 'https://www.teacherfashionista.com/backend/api/'
export const imgUrl = 'https://www.teacherfashionista.com/backend/public/storage/'
export const blogDtlUrl = 'https://www.teacherfashionista.com/#/BlogDetails/'



