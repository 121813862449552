import {useRef, useState} from "react";
const ShopProdList = (props) => {
    const ref = useRef(null);
    let [state, setState] = useState("loading");
    const handleScript = (e) => {
        setState(e.type === "load" ? "ready" : "error");
    };
    return (
        <>
            {props.is_All ? 
                <ul className="lPostListing shopProdListing" id="productList">
                    {props.shop_product.map((items) => {
                        const pluginCode = items.shop_store_code.split('<script type="text/javascript">');
                        const pluginCodeScript = pluginCode[1].split('</script>');
                        props.sript_code.type = 'text/javascript';
                        props.sript_code.innerHTML = pluginCodeScript[0];
                        props.sript_code.async = true;
                        const productCodeOne = pluginCode[0] + '</div>'
                        document.getElementById("productList").appendChild(props.sript_code);
                        props.sript_code.addEventListener("error", handleScript);

                        return (
                            <>
                                <li ref={ref}>
                                    <div dangerouslySetInnerHTML={{ __html: productCodeOne }}></div>
                                </li>
                            </>
                        );
                    })}
                </ul> :
                <ul className="lPostListing shopProdListing" id="productList">
                    {props.shop_product.map((items) => {
                        if (props.shop_item === items.shop_id) {
                            const pluginCode = items.shop_store_code.split('<script type="text/javascript">');
                            const pluginCodeScript = pluginCode[1].split('</script>');
                            props.sript_code.type = 'text/javascript';
                            props.sript_code.innerHTML = pluginCodeScript[0];
                            props.sript_code.async = true;
                            const productCodeOne = pluginCode[0] + '</div>'
                            document.getElementById("productList").appendChild(props.sript_code);
                            props.sript_code.addEventListener("error", handleScript);
                            return (
                                <>
                                    <li ref={ref}>
                                        <div dangerouslySetInnerHTML={{ __html: productCodeOne }}></div>
                                    </li>
                                </>
                            );
                        }
                    })}
                </ul>
            }  
        </>
    );
}
export default ShopProdList;