import { useEffect, useState } from "react";
import axios from 'axios';
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import PreLoader from "./PreLoader";
import NoImg from './assets/img/No-Image-Placeholder.png';
import { baseUrl, imgUrl, blogDtlUrl } from "./Constant";
const BlogDetails = () => {
    const [blogDtls, setBlogDtls] = useState({})
    const [blogTags, setBlogTags] = useState([])
    const [blogImgList, setBlogImgList] = useState([])
    const [similarPost, setSimilarPost] = useState([])
    const [latestPost, setLatestPost] = useState([])
    const [shopCategory, setShopCategory] = useState([])
    const [item, setItem] = useState('')
    const [loading, setLoading] = useState(true);
    const [pluginCodeOneHTML, setPluginCodeOneHTML] = useState('')
    const [pluginCodeTwoHTML, setPluginCodeTwoHTML] = useState('')

    const [storePlugin, setStorePlugin] = useState([]);
    const [blogStore, setBlogStore] = useState([]);

    const { slug_url } = useParams()
    const navigate = useNavigate()
    useEffect(() => {
        GetBlogDtls()
        GetLatestPost()
    }, [])

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const GetBlogDtls = () => {
        let data = { slug_url: slug_url }
        // let url = baseUrl + `get-blog-by-id`
        let url = baseUrl + `get-blog-by-id-list`
        axios.post(url, data)
            .then((resp) => {
                if (resp.status === 200) {
                    // console.log('=============', resp.data.result)
                    const date = new Date(resp.data.result.publish_date);
                    const options = { year: 'numeric', month: 'long', day: 'numeric' };
                    const formattedUpdateddAt = date.toLocaleDateString(undefined, options);

                    setBlogDtls({
                        ...resp.data.result,
                        publish_date: formattedUpdateddAt,
                    });
                
                    setBlogTags(resp.data.result.blog_tag_name)
                    setSimilarPost(resp.data.result.similar_tag_blog)
                    setBlogImgList(resp.data.result.blog_gallery_image)
                    setBlogStore(resp.data.result.blog_store)
                    resp.data.result.blog_store.map((storeItems) => {
                        const pluginCodeOne = storeItems.shop_store_code.split('<script type="text/javascript">');
                        const pluginCodeOneScript = pluginCodeOne[1].split('</script>');
                        const script = document.createElement('script');
                        script.type = 'text/javascript';
                        script.innerHTML = pluginCodeOneScript[0];
                        script.async = true;
                        document.head.appendChild(script);
                        storePlugin.push({ 'title_1': storeItems.shop_store_title_1, 'title_2': storeItems.shop_store_title_2, 'code': pluginCodeOne[0] + '</div>', 'imgstore1': storeItems.image_path_1, 'imgstore2': storeItems.image_path_2 })
                    })
                    setLoading(false)

                }
            }).catch((err) => {
                if (err.request.status === 401) {
                    console.log(err)
                }
            })
    }
    
    const GetLatestPost = () => {
        let url = baseUrl + `get-latest-eight-blog-list`
        // let url = baseUrl + `get-blog`
        axios.get(url)
            .then((resp) => {
                if (resp.status === 200) {
                    // console.log('result-------------', resp.data.result)
                    const formattedData = resp.data.result.map((val) => ({
                        ...val,
                        publish_date: formatDate(val.publish_date), 
                      }));
                    setLatestPost(formattedData)
                    resp.data.result.map((val) => {
                        shopCategory.map((obj, key) => {
                            if (obj === val['shopping_category_title']) {
                                shopCategory.splice(key, 1)
                            }
                        })
                        shopCategory.push(val['shopping_category_title'])
                    })
                    setLoading(false)
                }
            }).catch((err) => {
                if (err.request.status === 401) {
                    console.log(err)
                }
            })
    }

    function changeLocation(placeToGo) {
        navigate(placeToGo, { replace: true });
        window.location.reload();
        window.scrollTo(0, 0)
    }
    return (
        <>
            {loading && <PreLoader />}
            {!loading &&
                <>
                    <Header />
                    <div className="mainContent">
                        <div className="blogDtlWrap">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="titleWrap">
                                            <h2>{blogDtls.title}</h2>
                                            <p>{blogDtls.sub_title}</p>
                                            <h3 className="dateTxt">{blogDtls.publish_date}</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <ul className="tagList">
                                            {blogTags.map((val) => {
                                                return (
                                                    <li>
                                                        <a>{val.title}</a>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>

                                {blogDtls.image_path && (
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="mainImgWrap">
                                                <img src={`${imgUrl}${blogDtls.image_path}`} className="img-fluid" alt="Blog Image" />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className="row">
                                    {similarPost.length === 0 ? (
                                        <div className="col-md-12">
                                            <div id="blogDetails" className="blogDtlContent">
                                                <div className="topData">
                                                    <div className="dataWrap">
                                                        <p dangerouslySetInnerHTML={{ __html: blogDtls.description }}></p>
                                                    </div>
                                                </div>
                                                <ul className="blogImgListing">
                                                    {blogImgList.map((blogImgs) => {
                                                        return (
                                                            <>
                                                                <li>
                                                                    <div className="imgWrap">
                                                                        <img src={`${imgUrl}${blogImgs.image_path}`} className="img-fluid" />
                                                                    </div>
                                                                </li>
                                                            </>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <div className="col-md-9">
                                                <div id="blogDetails" className="blogDtlContent">
                                                    <div className="topData">
                                                        <div className="dataWrap">
                                                            <p dangerouslySetInnerHTML={{ __html: blogDtls.description }}></p>
                                                        </div>
                                                    </div>
                                                    <ul className="blogImgListing">
                                                        {blogImgList.map((blogImgs) => {
                                                            return (
                                                                <>
                                                                    <li>
                                                                        <div className="imgWrap">
                                                                            <img src={`${imgUrl}${blogImgs.image_path}`} className="img-fluid" />
                                                                        </div>
                                                                    </li>
                                                                </>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                {similarPost.length === 0 ? '' :
                                                    <div className="relatedBlogWrap">
                                                        <h2>Similar Posts</h2>
                                                        <ul>
                                                            {similarPost.slice(0, 8).map((val) => {
                                                                return (
                                                                    <li>
                                                                        <Link to={`/BlogDetails/${val.slug_url}`} onClick={() => changeLocation(`/BlogDetails/${val.slug_url}`)}>
                                                                            <div className="imgWrap">
                                                                                <img src={`${imgUrl}${val.thumbnail_path}`} className="img-fluid" />
                                                                                <p className="dateTxt">{val.publish_date}</p>
                                                                            </div>
                                                                            <div className="dataWrap">
                                                                                <h3>{val.title}</h3>
                                                                                <div className="descriptionWrap">
                                                                                    <p dangerouslySetInnerHTML={{ __html: val.short_description }}></p>
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </div>
                                                }
                                            </div>
                                        </>
                                    )}
                                </div>

                            </div>
                        </div>

                        {(storePlugin.length > 0) && (
                             <div className={`instaFeedWrap ${!storePlugin.some(storeTtl => storeTtl.imgstore1 || storeTtl.imgstore2) ? '' : 'vlogDtlShop'}`}>
                                {storePlugin.length === 1 ? (
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="instaFeedBox foronlyOneStroe">
                                                    <div className={`row ${!storePlugin.some(storeTtl => storeTtl.imgstore1 || storeTtl.imgstore2) ? 'justify-content-center' : 'justify-content-between'} align-items-center`}>
                                                        {storePlugin.some(storeTtl => storeTtl.imgstore1 || storeTtl.imgstore2) && (
                                                            <div className="col-md-5">
                                                                {storePlugin.map((storeTtl) => (
                                                                    <div className="imgWrap">
                                                                        <div className="topImg">
                                                                            {storeTtl.imgstore1 && <img src={`${imgUrl}${storeTtl.imgstore1}`} className="img-fluid" />}
                                                                        </div>
                                                                        <div className="btmImg">
                                                                            {storeTtl.imgstore2 && <img src={`${imgUrl}${storeTtl.imgstore2}`} className="img-fluid" />}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                        <div className={`col-md-${storePlugin.some(storeTtl => storeTtl.imgstore1 || storeTtl.imgstore2) ? '6 col-sm-6' : '6'}`}>
                                                            <ul className="padRt">
                                                                {storePlugin.map((storeTtl) => (
                                                                    <li>
                                                                        <h2>
                                                                            <span>{storeTtl.title_1}</span> {storeTtl.title_2}
                                                                        </h2>
                                                                        <div dangerouslySetInnerHTML={{ __html: storeTtl.code }}></div>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <p>
                                                                FOLLOW ME ON INSTAGRAM : <a href="#">@teacherfashionista</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                ) : (
                                    <div className="container-fluid">
                                        {storePlugin.reduce((groups, storeTtl, index) => {
                                            if (index % 2 === 0) {
                                                groups.push([]);
                                            }
                                            groups[groups.length - 1].push(storeTtl);
                                            return groups;
                                        }, []).map((group, groupIndex, allGroups) => (
                                            <div className={`row ${groupIndex === allGroups.length - 1 ? "lastGroup" : ""}`} key={groupIndex}>
                                                <div className="col-md-12">
                                                    <div className="instaFeedBox forMultipleFeeds">
                                                        <div className="row justify-content-center">
                                                            <div className="col-md-12">
                                                                <ul className="padRt">
                                                                    {group.map((storeTtl) => (
                                                                        <li>
                                                                            <h2>
                                                                                <span>{storeTtl.title_1}</span> {storeTtl.title_2}
                                                                            </h2>
                                                                            <div dangerouslySetInnerHTML={{ __html: storeTtl.code }}></div>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <p>
                                                                    FOLLOW ME ON INSTAGRAM : <a href="#">@teacherfashionista</a>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}


                        <div className="latestPostWrap formart">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="titleWrap">
                                            <h2>Latest Posts</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-md-5">
                                        <ul className="filterList">
                                            <li>
                                                <button onClick={(e) => setItem('')} className="btnCat" style={item === '' ? { backgroundColor: '#161616', color: '#ffffff' } : {}}>All</button>
                                            </li>
                                            {shopCategory.map((val) => {
                                                return (
                                                    <li>
                                                        <button onClick={(e) => { setItem(val) }} className="btnCat" style={item === val ? { backgroundColor: '#161616', color: '#ffffff' } : {}}>{val}</button>
                                                    </li>
                                                )
                                            })
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <ul className="lPostListing">
                                            {item ? latestPost.filter(name => name['shopping_category_title'] === item).map((latestPostVal) => {
                                                return (
                                                    <>
                                                        <li>
                                                            <div className="itemWrap">
                                                                <Link to={`/BlogDetails/${latestPostVal.slug_url}`} onClick={() => changeLocation(`/BlogDetails/${latestPostVal.slug_url}`)}>
                                                                    <div className="imgWrap">
                                                                        <img src={`${imgUrl}${latestPostVal.thumbnail_path}`} className="img-fluid" />
                                                                        <p className="dateTxt">{latestPostVal.publish_date}</p>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                            <div className="dataWrap">
                                                                <span>{latestPostVal.shopping_category_title}</span>
                                                                <Link to={`/BlogDetails/${latestPostVal.slug_url}`} onClick={() => changeLocation(`/BlogDetails/${latestPostVal.slug_url}`)}>
                                                                    <h3>{latestPostVal.title}</h3>
                                                                </Link>
                                                                <div className="descripWrap">
                                                                    <p dangerouslySetInnerHTML={{ __html: latestPostVal.short_description }}></p>
                                                                </div>
                                                                <Link to={`/BlogDetails/${latestPostVal.slug_url}`} className="seePost" onClick={() => changeLocation(`/BlogDetails/${latestPostVal.slug_url}`)}>
                                                                    see post <i className="fa-solid fa-chevron-right"></i>
                                                                </Link>
                                                            </div>
                                                        </li>
                                                    </>
                                                )
                                            }) : latestPost.slice(0, 8).map((latestPostVal) => {
                                                return (
                                                    <>
                                                        <li>
                                                            <div className="itemWrap">
                                                                <Link to={`/BlogDetails/${latestPostVal.slug_url}`} onClick={() => changeLocation(`/BlogDetails/${latestPostVal.slug_url}`)}>
                                                                    <div className="imgWrap">
                                                                        <img src={`${imgUrl}${latestPostVal.thumbnail_path}`} className="img-fluid" />
                                                                        <p className="dateTxt">{latestPostVal.publish_date}</p>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                            <div className="dataWrap">
                                                                <span>{latestPostVal.shopping_category_title}</span>
                                                                <Link to={`/BlogDetails/${latestPostVal.slug_url}`} onClick={() => changeLocation(`/BlogDetails/${latestPostVal.slug_url}`)}>
                                                                    <h3>{latestPostVal.title}</h3>
                                                                </Link>
                                                                <div className="descripWrap">
                                                                    <p dangerouslySetInnerHTML={{ __html: latestPostVal.short_description }}></p>
                                                                </div>
                                                                <Link to={`/BlogDetails/${latestPostVal.slug_url}`} className="seePost" onClick={() => changeLocation(`/BlogDetails/${latestPostVal.slug_url}`)}>
                                                                    see post <i className="fa-solid fa-chevron-right"></i>
                                                                </Link>
                                                            </div>
                                                        </li>
                                                    </>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="ctasWrap">
                                            <Link to={`/LatestBlog`} className="ctaTxt">View More Blogs</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </>
            }
        </>
    );
}
export default BlogDetails;