import { useEffect, useState } from "react";
import axios from 'axios';
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import PreLoader from "./PreLoader";
import Pagination from "./Pagination";
import { baseUrl, imgUrl } from "./Constant";
import NoImg from './assets/img/No-Image-Placeholder.png';
const FavoritesBlog = () => {
    const navigate = useNavigate()
    const [favoriteBlog, setFavoriteBlog] = useState([])
    const [totalBlogs, setTotalBlogs] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        GetFavoriteBlog(currentPage)
    }, [])
    const GetFavoriteBlog = (favPageNo) => {
        let url = baseUrl + `get-blog-favorite?page_no=${favPageNo}`
        axios.post(url)
            .then((resp) => {
               // console.log(resp)
                if (resp.status === 200) {
                    setFavoriteBlog(resp.data.result)
                    setCurrentPage(resp.data.current_page)
                    setPostsPerPage(resp.data.current_page_size)
                    setTotalBlogs(resp.data.total_items)
                    setTotalPages(resp.data.total_pages)
                    setLoading(false)
                }
            }).catch((err) => {
                if (err.request.status === 401) {
                    console.log(err)
                }
            })
    }

    const sliceData = () => {
        const startIndex = (currentPage - 1) * postsPerPage;
        const endIndex = startIndex + postsPerPage;
        return favoriteBlog.slice(startIndex, endIndex);
    };

    const handlePageChange = (pageNumber) => {
        setTotalPages(0)
        setCurrentPage(pageNumber);
        GetFavoriteBlog(pageNumber);
    };
    const handleClick = (placeToGo) =>{
        navigate(placeToGo, { replace: true });
        window.location.reload();
        window.scrollTo(0, 0)
    }
    return (
        <>
            {loading && <PreLoader />}
            {!loading &&
                <>
                    <Header />
                    <div className="mainContent">
                        <div className="latestPostWrap">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="titleWrap">
                                            <h2>Favorites</h2>
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <ul className="lPostListing">
                                            {favoriteBlog.map((favBlog) => {
                                                return (
                                                    <li>
                                                        <div className="itemWrap">
                                                            <Link to={`/BlogDetails/${favBlog.slug_url}`} onClick={() => handleClick(`/BlogDetails/${favBlog.slug_url}`)}>
                                                                <div className="imgWrap">
                                                                    <img src={`${imgUrl}${favBlog.thumbnail_path}`} className="img-fluid" />
                                                                </div>
                                                            </Link>
                                                        </div>
                                                        <div className="dataWrap">
                                                            {favBlog.blog_tag_name.map((blogTag) => {
                                                                return (
                                                                    <span>{blogTag.title}</span>
                                                                )
                                                            })}
                                                            <h3>{favBlog.title}</h3>
                                                            <div className="descripWrap">
                                                                <p dangerouslySetInnerHTML={{ __html: favBlog.description }}></p>
                                                            </div>
                                                            <Link to={`/BlogDetails/${favBlog.slug_url}`} onClick={() => handleClick(`/BlogDetails/${favBlog.slug_url}`)} className="seePost">
                                                                see post <i className="fa-solid fa-chevron-right"></i>
                                                            </Link>
                                                        </div>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="paginationPgeWrap">
                            {/* <button onClick={handleClickPrev} className="btnPage" disabled={currentPage === 1}>
                                Previous
                            </button> */}
                            <Pagination
                                totalPages={totalPages}
                                currentPage={currentPage}
                                onPageChange={handlePageChange}
                            />
                            {/* <button onClick={handleClickNext} className="btnPage" disabled={currentPage === totalPages}>
                                Next
                            </button> */}
                        </div>
                    </div>
                    <Footer />
                </>
            }
        </>
    );
}
export default FavoritesBlog;