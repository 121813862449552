import { useEffect, useState } from "react";
import axios from 'axios';
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { baseUrl, imgUrl } from "./Constant";
import NoImg from './assets/img/No-Image-Placeholder.png';
const BlogList = () => {
    const navigate = useNavigate()
    const [blogCategory, setBlogCategory] = useState([])
    const [favoriteBlog, setFavoriteBlog] = useState([])
    const [latestPost, setLatestPost] = useState([])
    const [shopCategory, setShopCategory] = useState([])
    const [blogStores, setBlogStores] = useState({})
    const [pluginCodeOneHTML, setPluginCodeOneHTML] = useState('')
    const [pluginCodeTwoHTML, setPluginCodeTwoHTML] = useState('')
    const [item, setItem] = useState('')
    useEffect(() => {
        GetFavoriteBlog()
        GetLatestPost()
        GetCategory()
    }, [])
    const GetCategory = () => {
        let url = baseUrl + `get-product-category`
        axios.get(url)
        .then((resp) => {
            if (resp.status === 200) {
                setBlogCategory(resp.data.result)             
            }
        }).catch((err) => {
            if (err.request.status === 401) {
                console.log(err)
            }
        })
    }
    const GetFavoriteBlog = () => {
        let url = baseUrl + `get-blog-favorite`
        axios.post(url)
        .then((resp) => {
            if (resp.status === 200) { 
                setFavoriteBlog(resp.data.result)             
            }
        }).catch((err) => {
            if (err.request.status === 401) {
                console.log(err)
            }
        })
    }
    const GetLatestPost = () => {
        let url = baseUrl + `get-blog`
        axios.post(url)
            .then((resp) => {
                if (resp.status === 200) {
                    //console.log('blog Latest------', resp.data.result)
                    setLatestPost(resp.data.result)
                    resp.data.result.map ((val) =>{
                        shopCategory.map((obj, key) =>{
                            if(obj === val['shopping_category_title']){
                                shopCategory.splice(key, 1)
                            }
                        })
                        shopCategory.push(val['shopping_category_title'])
                    })
                    setBlogStores(resp.data.blog_shop_store)
                    const pluginCodeOne = resp.data.blog_shop_store.shop_store_code_1.split('<script type="text/javascript">');
                    const pluginCodeOneScript = pluginCodeOne[1].split('</script>');
                    const script = document.createElement('script');
                    script.type = 'text/javascript';
                    script.innerHTML = pluginCodeOneScript[0];
                    script.async = true;
                    document.head.appendChild(script);
                    setPluginCodeOneHTML(pluginCodeOne[0] + '</div>')

                    const pluginCodeTwo = resp.data.blog_shop_store.shop_store_code_2.split('<script type="text/javascript">');
                    const pluginCodeTwoScript = pluginCodeTwo[1].split('</script>');
                    script.innerHTML = pluginCodeTwoScript[0];
                    setPluginCodeTwoHTML(pluginCodeTwo[0] + '</div>')
                }
            }).catch((err) => {
                if (err.request.status === 401) {
                    console.log(err)
                }
            })
    }
    const handleClick = (placeToGo) =>{
        navigate(placeToGo, { replace: true });
        window.location.reload();
        window.scrollTo(0, 0)
    }
    return (
        <>
            <Header />
            <div className="mainContent">
                <div className="blogCatWrap">
                    <div className="container-fluid bigScreenWid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="blogCatBox">
                                    <div className="leftData">
                                        {blogCategory.slice(0, 1).map((blogValOne) => {
                                            return (
                                                <div className="leftImgWrap">
                                                    <div className="imgWrap">
                                                        <img src={`${imgUrl}${blogValOne.thumbnail_path}`} className="img-fluid" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h3>{blogValOne.title}</h3>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className="rightData">
                                        <ul>
                                            {blogCategory.slice(1, 5).map((blogValRight) => {
                                                return (
                                                    <li>
                                                        <div className="itemWrap">
                                                            <a href="#">
                                                                <div className="imgWrap">
                                                                    <img src={`${imgUrl}${blogValRight.thumbnail_path}`} className="img-fluid" />
                                                                </div>
                                                                <div className="dataWrap">
                                                                    <h3>{blogValRight.title}</h3>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {blogCategory.length > 5 && 
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="ctaWrap">
                                        <Link to={`/BlogListTwo`} onClick={() => handleClick(`/BlogListTwo`)}>View More Categories</Link>
                                    </div>
                                </div>
                            </div>       
                        }
                    </div>
                </div>
                <div className="latestPostWrap">
                    <div className="container-fluid bigScreenWid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="titleWrap">
                                    <h2>Latest Posts</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-5">
                                <ul className="filterList">
                                    <li>
                                        <button onClick={(e) => setItem('')} className="btnCat" style={item === '' ? { backgroundColor: '#161616', color: '#ffffff' } : {}}>All</button>
                                    </li>
                                    {shopCategory.map((val) => {
                                        return (
                                            <li>
                                                <button onClick={(e) => { setItem(val) }} className="btnCat" style={item === val ? { backgroundColor: '#161616', color: '#ffffff' } : {}}>{val}</button>
                                            </li>
                                        )
                                    })
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <ul className="lPostListing">
                                    {item ? latestPost.filter(name => name['shopping_category_title'] === item).map((latestPostVal) => {
                                        return (
                                            <>
                                                <li>
                                                    <div className="itemWrap">
                                                        <Link to={`/BlogDetails/${latestPostVal.slug_url}`} onClick={() => handleClick(`/BlogDetails/${latestPostVal.slug_url}`)}>
                                                            <div className="imgWrap">
                                                                <img src={`${imgUrl}${latestPostVal.thumbnail_path}`} className="img-fluid" />
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="dataWrap">
                                                        <span>{latestPostVal.shopping_category_title}</span>
                                                        <Link to={`/BlogDetails/${latestPostVal.slug_url}`} onClick={() => handleClick(`/BlogDetails/${latestPostVal.slug_url}`)}>
                                                            <h3>{latestPostVal.title}</h3>
                                                        </Link>
                                                        <div className="descripWrap">
                                                            <p dangerouslySetInnerHTML={{ __html: latestPostVal.description }}></p>
                                                        </div>
                                                        <Link to={`/BlogDetails/${latestPostVal.slug_url}`}  onClick={() => handleClick(`/BlogDetails/${latestPostVal.slug_url}`)} className="seePost">
                                                            see post <i className="fa-solid fa-chevron-right"></i>
                                                        </Link>
                                                    </div>
                                                </li>
                                            </>
                                        )
                                    }): latestPost.slice(0, 8).map((latestPostVal) => {
                                        return (
                                            <>
                                                <li>
                                                    <div className="itemWrap">
                                                        <Link to={`/BlogDetails/${latestPostVal.slug_url}`} onClick={() => handleClick(`/BlogDetails/${latestPostVal.slug_url}`)}>
                                                            <div className="imgWrap">
                                                                <img src={`${imgUrl}${latestPostVal.thumbnail_path}`} className="img-fluid" />
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="dataWrap">
                                                        <span>{latestPostVal.shopping_category_title}</span>
                                                        <Link to={`/BlogDetails/${latestPostVal.slug_url}`} onClick={() => handleClick(`/BlogDetails/${latestPostVal.slug_url}`)}>
                                                            <h3>{latestPostVal.title}</h3>
                                                        </Link>
                                                        <div className="descripWrap">
                                                            <p dangerouslySetInnerHTML={{ __html: latestPostVal.description }}></p>
                                                        </div>
                                                        <Link to={`/BlogDetails/${latestPostVal.slug_url}`}  onClick={() => handleClick(`/BlogDetails/${latestPostVal.slug_url}`)} className="seePost">
                                                            see post <i className="fa-solid fa-chevron-right"></i>
                                                        </Link>
                                                    </div>
                                                </li>
                                            </>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="ctasWrap">
                                    <Link to={`/LatestBlog`} className="ctaTxt">View More Blogs</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="instaFeedWrap">
                    <div className="container-fluid bigScreenWid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="instaFeedBox">
                                    <div className="row justify-content-between">
                                        <div className="col-md-6">
                                            <h2><span>{blogStores.shop_store_1_title_1}</span> {blogStores.shop_store_1_title_2}</h2>
                                            <ul className="padRt">
                                                <li>
                                                    <div dangerouslySetInnerHTML={{__html: pluginCodeOneHTML}}></div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-md-6">
                                            <h2><span>{blogStores.shop_store_2_title_1}</span> {blogStores.shop_store_2_title_2}</h2>
                                            <ul className="padLt">
                                                <li>
                                                    <div dangerouslySetInnerHTML={{__html: pluginCodeTwoHTML}}></div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <p>FOLLOW ME ON INSTAGRAM : <a href="#"> @teacherfashionista</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="favoritesWrap">
                    <div className="container-fluid bigScreenWid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="titleWrap">
                                    <h2>Favorites</h2>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <ul>
                                    {favoriteBlog.slice(0, 3).map((favBlog) => {
                                        return (
                                            <li>
                                                <div className="topImgWrap favItem">
                                                    <div className="imgWrap">
                                                        <Link to={`/BlogDetails/${favBlog.slug_url}`} onClick={() => handleClick(`/BlogDetails/${favBlog.slug_url}`)}>
                                                            <img src={`${imgUrl}${favBlog.thumbnail_path}`} className="img-fluid" />
                                                        </Link>
                                                    </div>
                                                    <div className="imgData">
                                                        {favBlog.blog_tag_name.map((blogTag) => {
                                                            return (
                                                                <span>{blogTag.title}</span>
                                                            )
                                                        })}
                                                        <Link to={`/BlogDetails/${favBlog.slug_url}`} onClick={() => handleClick(`/BlogDetails/${favBlog.slug_url}`)}>
                                                            <h3>{favBlog.title}</h3>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })}
                                    <li className="quoteTxt">
                                        <div className="favDataDesc">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                        </div>
                                    </li>
                                    <li className="bigWid">
                                        <div className="bgClrtxt">
                                            <div className="btmDataWrap">
                                                <h3>For travellers and dreamers</h3>
                                                <p>What to Buy from the Tory Burch
                                                    Black Friday Sale</p>
                                                <a href="#">Learn more</a>
                                            </div>
                                        </div>
                                    </li>
                                    {favoriteBlog.slice(3, 6).map((favBlog) => {
                                        return (
                                            <>
                                                <li>
                                                    <div className="topImgWrap favItem">
                                                        <div className="imgWrap">
                                                            <Link to={`/BlogDetails/${favBlog.slug_url}`} onClick={() => handleClick(`/BlogDetails/${favBlog.slug_url}`)}>
                                                                <img src={`${imgUrl}${favBlog.thumbnail_path}`} className="img-fluid" />
                                                            </Link>
                                                        </div>
                                                        <div className="imgData">
                                                            {favBlog.blog_tag_name.map((blogTag) => {
                                                                return (
                                                                    <span>{blogTag.title}</span>
                                                                )
                                                            })}
                                                            <Link to={`/BlogDetails/${favBlog.slug_url}`} onClick={() => handleClick(`/BlogDetails/${favBlog.slug_url}`)}>
                                                                <h3>{favBlog.title}</h3>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </li>
                                            </>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="ctasWrap">
                                    <Link to={`/FavoritesBlog`} className="ctaTxt">View More Blogs</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
export default BlogList;