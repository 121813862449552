import { useEffect, useState } from "react";
import axios from 'axios';
import { Link, useNavigate, useParams } from "react-router-dom";
import { baseUrl, imgUrl } from "./Constant";
import PreLoader from "./PreLoader";
import Header from "./Header";
import Footer from "./Footer";
const Collaborate = () => {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [messages, setMessages] = useState('')
    const [successMsg, setSuccessMsg] = useState({})
    const [conactDtls, setConactDtls] = useState({})
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        GetContactDtls()
    }, [])
    const GetContactDtls = () => {
        axios.get(baseUrl + `get-contactinfo`)
            .then((resp) => {
                if (resp.status === 200) {
                    setConactDtls(resp.data.result)
                    setLoading(false)
                }
            })
            .catch((err) => {
                if (err.request.status === 401) {
                    console.log(err)
                }
            })
    }
    return (
        <>
            {loading && <PreLoader />}
            {!loading &&
                <>
                    <Header />
                    <div className="mainContent">
                        <div className="contactPageWrap collaborateWrap">
                            <div className="container-fluid bigScreenWid">
                                <div className="row justify-content-center">
                                    <div className="col-sm-12 col-md-8 col-lg-6">
                                        <div className="titleWrap">
                                            <h2>Collaborate</h2>
                                            <p>For inquiries regarding collaborations and partnerships, please email me at <a href="mailto:teacherfashionista@gmail.com">teacherfashionista@gmail.com</a>, or fill out the form below.</p>
                                        </div>
                                        {successMsg['msg'] &&
                                            <div class="alert alert-success mb-4" role="alert">
                                                <strong>{successMsg['msg']}</strong>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-md-6">
                                        <div className="contactWrap">
                                            <div className="contactFormWrap">
                                                <form onSubmit={(e) => {
                                                    e.preventDefault()
                                                    let value = {
                                                        first_name: firstName,
                                                        last_name: lastName,
                                                        email: email,
                                                        phone_no: phoneNumber,
                                                        message: messages
                                                    }
                                                    axios.post(baseUrl + 'add-contactinfo', value)
                                                        .then((resp) => {
                                                            if (resp.status === 200) {
                                                                setFirstName("")
                                                                setLastName("")
                                                                setEmail("")
                                                                setPhoneNumber("")
                                                                setSuccessMsg(resp.data)
                                                                console.log('this is true', resp.data.msg)
                                                            }
                                                            else {
                                                                console.log(resp)
                                                                console.log('something went wrong....')
                                                            }
                                                        })
                                                        .catch((err) => {
                                                            console.log(err)
                                                            setSuccessMsg({})
                                                        })
                                                }}>
                                                    <ul className="formList">
                                                        <li>
                                                            <div className="formField">
                                                                <label>First name<span>*</span></label>
                                                                <input type="text" placeholder="First name" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="formField">
                                                                <label>Last name<span>*</span></label>
                                                                <input type="text" placeholder="Last name" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="formField">
                                                                <label>Email<span>*</span></label>
                                                                <input type="text" placeholder="you@company.com" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="formField">
                                                                <label>Message<span>*</span></label>
                                                                <textarea value={messages} onChange={(e) => setMessages(e.target.value)} required></textarea>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="ctaWrap">
                                                                <button type="submit" className="btn">Send Message</button>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </>
            }
        </>
    );
}
export default Collaborate;