import { useEffect, useState } from "react";
import axios from 'axios';
import Header from "./Header";
import Footer from "./Footer";
import { baseUrl } from "./Constant";
import PreLoader from "./PreLoader";
const Widgets = () => {
    const [shopWidget, setShopWidget] = useState([]);
    const [storePlugin, setStorePlugin] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        GetShopWidget()
    }, [])
    const GetShopWidget = () => {
        let url = baseUrl + `get-ecommerce-widget`
        axios.get(url)
            .then((resp) => {
                if (resp.status === 200) {
                    setShopWidget(resp.data.result);
                    resp.data.result.map((storeItems) => {
                        const pluginCodeOne = storeItems.widget_text.split('<script type="text/javascript">');
                        const pluginCodeOneScript = pluginCodeOne[1].split('</script>');
                        const script = document.createElement('script');
                        script.type = 'text/javascript';
                        script.innerHTML = pluginCodeOneScript[0];
                        script.async = true;
                        document.head.appendChild(script);
                        storePlugin.push({ 'title': storeItems.title, 'code': pluginCodeOne[0] + '</div>' })
                    })
                    setLoading(false)
                }
            }).catch((err) => {
                if (err.resp.status === 401) {
                    console.log(err)
                }
            })
    }
    return (
        <>
            {loading && <PreLoader />}
            {!loading &&
                <>
                    <Header />
                    <div className="mainContent">
                        <div className="latestPostWrap shopPageWidget">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="titleWrap">
                                            <h2>Shop My Favorites</h2>
                                            <p>Here are some of the things I'm loving right now.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-md-12">
                                        <ul className="shopWidgetList">
                                            {storePlugin.map((storeTtl) => (
                                                <li>
                                                    <h2>{storeTtl.title}</h2>
                                                    <div className="shopWidgetBoxWrap">
                                                        <div className="shopWidgetBox" dangerouslySetInnerHTML={{ __html: storeTtl.code }}></div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </>
            }
        </>
    );
}
export default Widgets;