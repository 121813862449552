import { useEffect, useState } from "react";
import axios from 'axios';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import PreLoader from "./PreLoader";
import { baseUrl, imgUrl } from "./Constant";
const About = () => {
    const [sectionOne, setSectionOne] = useState({});
    const [sectionTwo, setSectionTwo] = useState({});
    const [sectionThree, setSectionThree] = useState({});
    const [mission, setMission] = useState({});
    const [vision, setVision] = useState({});
    const [loading, setLoading] = useState(true);
    const [instaFeeds, setInstaFeeds] = useState([]);
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5,
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 4,
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 3,
        },
    };

    useEffect(() => {
        GetAboutPage()
        getInstaFeed()
    }, [])
    const GetAboutPage = () => {
        axios.get(baseUrl + `get-aboutus`)
            .then((resp) => {
                if (resp.status === 200) {
                    setSectionOne(resp.data.result.section_1)
                    setSectionTwo(resp.data.result.section_2)
                    setSectionThree(resp.data.result.section_3)
                    setMission(resp.data.result.our_mission)
                    setVision(resp.data.result.our_vision)
                    setLoading(false)
                    console.log('+++++++++++', resp.data.result.section_1)
                }
            })
            .catch((err) => {
                if (err.request.status === 401) {
                    console.log(err)
                }
            })
    }
    const getInstaFeed = () => {
        axios.get(baseUrl + `get-instagram-feed`)
            .then((resp) => {
                if (resp.status === 200) {
                    setInstaFeeds(resp.data.result);
                }
            }).catch((err) => {
                if (err.request.status === 401) {
                    console.log(err)
                }
            })
    }

    const CustomButtonGroup = ({ next, previous }) => (
        <div className="custom-button-group">
            <button className="custom-button prev" onClick={previous}>
                Prev
            </button>
            <button className="custom-button next" onClick={next}>
                Next
            </button>
        </div>
    );

    return (
        <>
            {loading && <PreLoader />}
            {!loading &&
                <>
                    <Header />
                    <div className="mainContent">
                        <div className="aboutWrap">
                            <div className="container-fluid bigScreenWid">
                                <div className="row">
                                    <div className="col-md-8 col-6 flexOr-1">
                                        <div className="imgWrap">
                                            <img src={`${imgUrl}${sectionOne.section_1_image_path_1}`} className="img-fluid" />
                                        </div>
                                        <div className="contentWrap mobDisNone">
                                            <div className="topDataWrap">
                                                <span>{sectionOne.section_1_title_1}</span>
                                                <h2>{sectionOne.section_1_title_2}</h2>
                                            </div>
                                            <div className="btmDataWrap">
                                                <p dangerouslySetInnerHTML={{ __html: sectionOne.section_1_text }}></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-6 flexOr-2">
                                        <div className="imgWrap imgWrap2">
                                            <img src={`${imgUrl}${sectionOne.section_1_image_path_2}`} className="img-fluid" />
                                        </div>
                                    </div>
                                    <div className="col-md-12 forMobileDisply flexOr-3">
                                        <div className="contentWrap">
                                            <div className="topDataWrap">
                                                <span>{sectionOne.section_1_title_1}</span>
                                                <h2>{sectionOne.section_1_title_2}</h2>
                                            </div>
                                            <div className="btmDataWrap">
                                                <p dangerouslySetInnerHTML={{ __html: sectionOne.section_1_text }}></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="startWithBx1Wrap">
                            <div className="container-fluid bigScreenWid">
                                <div className="row justify-content-start">
                                    <div className="col-md-12">
                                        <div className="row align-items-center">
                                            <div className="col-md-8 flexOr-2">
                                                <div className="dataWrap">
                                                    <div className="abtTtl">
                                                        <span>{sectionTwo.section_2_title_1}</span>
                                                        <h2>{sectionTwo.section_2_title_2}</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="dataWrap">
                                                    <p dangerouslySetInnerHTML={{ __html: sectionTwo.section_2_text }}></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="startWithBx2Wrap">
                            <div className="container-fluid bigScreenWid">
                                <div className="row justify-content-center posRel">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="imgWrap">
                                                    <img src={`${imgUrl}${sectionThree.section_3_image_path_1}`} className="img-fluid" />
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="dataWrap dataWrapTop">
                                                    <div className="abtTtl">
                                                        <span>{sectionThree.section_3_title_1}</span>
                                                        <h2>{sectionThree.section_3_title_2}</h2>
                                                    </div>
                                                    <p dangerouslySetInnerHTML={{ __html: sectionThree.section_3_text_1 }}></p>
                                                </div>
                                                <div className="dataWrap dataWrapBtm">
                                                    <p dangerouslySetInnerHTML={{ __html: sectionThree.section_3_text_2 }}></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="aboutPageInstaFeed">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="titleWrap">
                                            <h2>Instagram Feed</h2>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col-md-12 pad0">
                                        <Carousel responsive={responsive} customButtonGroup={<CustomButtonGroup />}>
                                            {instaFeeds.map((instaItem) => (
                                                <div key={instaItem.id}>
                                                    <Link
                                                        to={instaItem.link}
                                                        target='_blank'
                                                    >
                                                        <div className="imgWrap">
                                                            <img src={`${imgUrl}${instaItem.image_path}`} alt={instaItem.title} className="img-fluid" />
                                                            <div className="icoWrap">
                                                                <i className="fa-brands fa-instagram"></i>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            ))}
                                        </Carousel>
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col-md-12 pad0">
                                        {/* <InstagramEmbed
                                            url="https://www.instagram.com/teacherfashionista/?utm_source=ig_embed&amp;utm_campaign=loading"
                                            clientAccessToken="YOUR_ACCESS_TOKEN" // Replace with your Instagram access token
                                            maxWidth={540}
                                            hideCaption={false}
                                            containerTagName="div"
                                            protocol=""
                                            injectScript
                                        /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </>
            }
        </>
    );
}
export default About;