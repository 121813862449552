import { useEffect, useState } from "react";
import axios from 'axios';
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import PreLoader from "./PreLoader";
import Pagination from "./Pagination";
import { baseUrl, imgUrl } from "./Constant";
import NoImg from './assets/img/No-Image-Placeholder.png';
const LatestBlog = () => {
    const navigate = useNavigate()
    const [latestPost, setLatestPost] = useState([])
    const [shopCategory, setShopCategory] = useState([])
    const [loading, setLoading] = useState(true);
    const [totalBlogs, setTotalBlogs] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [item, setItem] = useState('');

    useEffect(() => {
        GetLatestPost(currentPage);
    }, [currentPage]);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

      
    const GetLatestPost = (latestPageNo) => {
        let url = baseUrl + `get-blog-list?page_no=${latestPageNo}`
        axios.post(url)
            .then((resp) => {
                if (resp.status === 200) {
                    // console.log('result-------------', resp.data.result)
                    const updatedLatestPost = resp.data.result.map((val) => {
                        const date = new Date(val.publish_date);

                        if (!isNaN(date.getTime())) {
                            const options = { year: 'numeric', month: 'long', day: 'numeric' };
                            const formattedUpdateddAt = date.toLocaleDateString(undefined, options);
    
                            return {
                                ...val,
                                publish_date: formattedUpdateddAt,
                            };
                        } else {
                            console.error(`Invalid date format: ${val.publish_date}`);
                            return val;
                        }
                    });

                    const { publish_date } = updatedLatestPost[0] || {};
                    setLatestPost(updatedLatestPost);
    
                    resp.data.result.forEach((val) => {
                        shopCategory.map((obj, key) => {
                            if (obj === val['shopping_category_title']) {
                                shopCategory.splice(key, 1);
                            }
                        });
                        shopCategory.push(val['shopping_category_title']);
                    });
    
                    setCurrentPage(resp.data.current_page);
                    setPostsPerPage(resp.data.current_page_size);
                    setTotalBlogs(resp.data.total_items);
                    setTotalPages(resp.data.total_pages);
                    setLoading(false);
                }
            })
            .catch((err) => {
                if (err.request.status === 401) {
                    console.log(err);
                }
            });
    };
    
    const sliceData = () => {
        const startIndex = (currentPage - 1) * postsPerPage;
        const endIndex = startIndex + postsPerPage;
        return latestPost.slice(startIndex, endIndex);
    };

    const handlePageChange = (pageNumber) => {
        setTotalPages(0)
        setCurrentPage(pageNumber);
        GetLatestPost(pageNumber);
    };
    const handleClick = (placeToGo) =>{
        navigate(placeToGo, { replace: true });
        window.location.reload();
        window.scrollTo(0, 0)
    }

    return (
        <>
            {loading && <PreLoader />}
            {!loading &&
                <>
                    <Header />
                    <div className="mainContent">
                        <div className="latestPostWrap">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="titleWrap">
                                            <h2>Latest Posts</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-md-5">
                                        <ul className="filterList">
                                            <li>
                                                <button onClick={(e) => setItem('')} className="btnCat" style={item === '' ? { backgroundColor: '#161616', color: '#ffffff' } : {}}>All</button>
                                            </li>
                                            {shopCategory.map((val) => {
                                                return (
                                                    <li>
                                                        <button onClick={(e) => { setItem(val) }} className="btnCat" style={item === val ? { backgroundColor: '#161616', color: '#ffffff' } : {}}>{val}</button>
                                                    </li>
                                                )
                                            })
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <ul className="lPostListing">
                                            {item ? latestPost.filter(name => name['shopping_category_title'] === item).map((latestPostVal) => {
                                                return (
                                                    <>
                                                        <li>
                                                            <div className="itemWrap">
                                                                <Link to={`/BlogDetails/${latestPostVal.slug_url}`} onClick={() => handleClick(`/BlogDetails/${latestPostVal.slug_url}`)}>
                                                                    <div className="imgWrap">
                                                                        <img src={`${imgUrl}${latestPostVal.thumbnail_path}`} className="img-fluid" />
                                                                        <p className="dateTxt">{latestPostVal.publish_date}</p>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                            <div className="dataWrap">
                                                                <span>{latestPostVal.shopping_category_title}</span>
                                                                <Link to={`/BlogDetails/${latestPostVal.slug_url}`} onClick={() => handleClick(`/BlogDetails/${latestPostVal.slug_url}`)}>
                                                                    <h3>{latestPostVal.title}</h3>
                                                                </Link>
                                                                <div className="descripWrap">
                                                                    <p dangerouslySetInnerHTML={{ __html: latestPostVal.short_description }}></p>
                                                                    {/* <p>{latestPostVal.short_description}</p> */}
                                                                </div>
                                                                <Link to={`/BlogDetails/${latestPostVal.slug_url}`} onClick={() => handleClick(`/BlogDetails/${latestPostVal.slug_url}`)} className="seePost">
                                                                    see post <i className="fa-solid fa-chevron-right"></i>
                                                                </Link>
                                                            </div>
                                                        </li>
                                                    </>
                                                )
                                            }) : latestPost.map((latestPostVal) => {
                                                return (
                                                    <>
                                                        <li>
                                                            <div className="itemWrap">
                                                                <Link to={`/BlogDetails/${latestPostVal.slug_url}`} onClick={() => handleClick(`/BlogDetails/${latestPostVal.slug_url}`)}>
                                                                    <div className="imgWrap">
                                                                        <img src={`${imgUrl}${latestPostVal.thumbnail_path}`} className="img-fluid" />
                                                                        <p className="dateTxt">{latestPostVal.publish_date}</p>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                            <div className="dataWrap">
                                                                <span>{latestPostVal.shopping_category_title}</span>
                                                                <Link to={`/BlogDetails/${latestPostVal.slug_url}`} onClick={() => handleClick(`/BlogDetails/${latestPostVal.slug_url}`)}>
                                                                    <h3>{latestPostVal.title}</h3>
                                                                </Link>
                                                                <div className="descripWrap">
                                                                    <p dangerouslySetInnerHTML={{ __html: latestPostVal.short_description }}></p>
                                                                    {/* <p>{latestPostVal.short_description}</p> */}
                                                                </div>
                                                                <Link to={`/BlogDetails/${latestPostVal.slug_url}`} onClick={() => handleClick(`/BlogDetails/${latestPostVal.slug_url}`)} className="seePost">
                                                                    see post <i className="fa-solid fa-chevron-right"></i>
                                                                </Link>
                                                            </div>
                                                        </li>
                                                    </>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="paginationPgeWrap">
                            {/* <button onClick={handleClickPrev} className="btnPage" disabled={currentPage === 1}>
                                Previous
                            </button> */}
                            <Pagination
                                totalPages={totalPages}
                                currentPage={currentPage}
                                onPageChange={handlePageChange}
                            />
                            {/* <button onClick={handleClickNext} className="btnPage" disabled={currentPage === totalPages}>
                                Next
                            </button> */}
                        </div>
                    </div>
                    <Footer />
                </>
            }
        </>
    );
}
export default LatestBlog;