import React from "react";
import { Route, Routes } from "react-router-dom";
import './App.css';
import Home from './components/Home';
import About from "./components/About";
import Contact from "./components/Contact";
import BlogDetails from "./components/BlogDetails";
import BlogList from "./components/BlogList";
import BlogListTwo from "./components/BlogListTwo";
import FavoritesBlog from "./components/Favorites";
import LatestBlog from "./components/LatestBlog";
import Shop from "./components/Shop";
import BlogListing from "./components/BlogListing";
import Faqs from "./components/Faqs";
import Collaborate from './components/Collaborate';
import Search from "./components/Search";
import BlogShopCategory from "./components/BlogShopCategory";
import Widgets from "../src/components/Widget";
function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="About" element={<About />} />
        <Route path="Contact" element={<Contact />} />
        <Route path="BlogList" element={<BlogList />} />
        <Route path="BlogDetails/:slug_url" element={<BlogDetails />}/>
        <Route path="BlogListTwo" element={<BlogListTwo />} />
        <Route path="FavoritesBlog" element={<FavoritesBlog />} />
        <Route path="LatestBlog" element={<LatestBlog />} />
        <Route path="BlogListing/:blogSubCat_id" element={<BlogListing />}/>
        <Route path="BlogShopCategory/:shopping_category_id" element={<BlogShopCategory />}/>
        <Route path="Shop" element={<Shop />} />
        <Route path="Faqs" element={<Faqs />} />
        <Route path="Collaborate" element={<Collaborate />} />
        <Route path="Search" element={<Search />} />
        <Route path="Widgets" element={<Widgets />} />
      </Routes>
    </>
  );
}

export default App;
