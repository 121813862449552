import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import PreLoader from "./PreLoader";
import { baseUrl, imgUrl } from "./Constant";

const Search = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    if (searchQuery.trim() !== "") {
      setLoading(true);
      const requestData = {
        text: searchQuery,
      };

      axios
        .post(baseUrl + 'search-blog', requestData)
        .then((resp) => {
          if (resp.status === 200) {
            console.log('Search result:', resp.data);
            const { result, current_page, total_pages } = resp.data;
            const searchResult = result || [];
            setSearchResults(searchResult);
            setCurrentPage(current_page);
            setTotalPages(total_pages);
            setLoading(false);
          }
        })
        .catch((err) => {
          if (err.request.status === 401) {
            console.log(err);
          }
        });
    } else {
      setSearchResults([]);
    }
  }, [searchQuery]);

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchQuery(e.target.value);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderResults = () => {
    if (searchResults.length > 0) {
      return (
        <div>
          <h2>Search Results for <span>"{searchQuery}"</span>:</h2>
          <ul>
            {searchResults.map((result) => (
              <li key={result.id}>
                <Link to={`/BlogDetails/${result.slug_url}`} className="searchResLink">
                  <div className="leftImgWrap">
                    <img src={`${imgUrl}${result.image_path}`} className="img-fluid" alt={result.title} />
                  </div>
                  <div className="rightDataWrap">
                    <h2 dangerouslySetInnerHTML={{ __html: highlightMatchedWords(result.title) }}></h2>
                    <h3 dangerouslySetInnerHTML={{ __html: highlightMatchedWords(result.sub_title) }}></h3>
                    <div className="descriptTxt" dangerouslySetInnerHTML={{ __html: highlightMatchedWords(result.description) }}></div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
          {totalPages > 1 && (
            <div className="pagination">
              {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                <button
                  key={page}
                  className={currentPage === page ? 'active' : ''}
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </button>
              ))}
            </div>
          )}
        </div>
      );
    } else {
      return (
        <p className="serachQueryTxt">No search results found for <span>"{searchQuery}"</span>.</p>
      );
    }
  };
  

  const highlightMatchedWords = (text) => {
    const wordsToHighlight = searchQuery.trim().split(" ");
    const regex = new RegExp(`(${wordsToHighlight.join("|")})`, 'gi');
    return text.replace(regex, "<span class='highlight'>$1</span>");
  };

  return (
    <>
      <Header />
      <div className="mainContent">
        <div className="searchPageWrap">
          <div className="container-fluid bigScreenWid">
            <div className="row">
              <div className="col-md-12">
                <div className="searchField">
                  <form onSubmit={(e) => e.preventDefault()}>
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={handleSearch}
                      placeholder="Enter your search query"
                    />
                    <button type="submit">Search</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* {loading && <PreLoader />} */}
          {searchQuery && !loading && (
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-10">
                  {renderResults()}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Search;
