import { useEffect, useState } from "react";
import axios from 'axios';
import Accordion from 'react-bootstrap/Accordion';
import Header from "./Header";
import Footer from "./Footer";
import PreLoader from "./PreLoader";
import { baseUrl, imgUrl } from "./Constant";
const Faqs = () => {
    const [faqSection, setFaqSection] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeIndex, setActiveIndex] = useState(null);
    useEffect(() => {
        GetFaqPage()
    }, [])
    const GetFaqPage = () => {
        axios.get(baseUrl + `get-faq`)
            .then((resp) => {
                if (resp.status === 200) {
                    setFaqSection(resp.data.result)
                    setLoading(false)
                }
            })
            .catch((err) => {
                if (err.request.status === 401) {
                    console.log(err)
                }
            })
    }
    return (
        <>
            {loading && <PreLoader />}
            {!loading &&
                <>
                    <Header />
                    <div className="mainContent">
                        <div className="faqsPageWrap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="titleWrap">
                                            <h2>FAQ's</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-md-9">
                                        <Accordion defaultActiveKey="0">
                                            {faqSection.map((val) => {
                                                return (
                                                    <>
                                                        <Accordion.Item eventKey={val.id}>
                                                            <Accordion.Header>{val.question}</Accordion.Header>
                                                            <Accordion.Body>
                                                                <p>{val.answer}</p>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </>
                                                );
                                            })}
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </>
            }
        </>
    );
}
export default Faqs;