import React from "react";
const PreLoader = () => {
    return (
        <div id="preloader">
            <div className="preloader-inner">
                <div className="spinner"></div>
                <div className="loading-text">
                    <span data-preloader-text="T" className="characters">T</span>
                    <span data-preloader-text="E" className="characters">E</span>
                    <span data-preloader-text="A" className="characters">A</span>
                    <span data-preloader-text="C" className="characters">C</span>
                    <span data-preloader-text="H" className="characters">H</span>
                    <span data-preloader-text="E" className="characters">E</span>
                    <span data-preloader-text="R" className="characters">R</span>
                    <span className="charactersSpace"></span>
                    <span data-preloader-text="F" className="characters">F</span>
                    <span data-preloader-text="A" className="characters">A</span>
                    <span data-preloader-text="S" className="characters">S</span>
                    <span data-preloader-text="H" className="characters">H</span>
                    <span data-preloader-text="I" className="characters">I</span>
                    <span data-preloader-text="O" className="characters">O</span>
                    <span data-preloader-text="N" className="characters">N</span>
                    <span data-preloader-text="I" className="characters">I</span>
                    <span data-preloader-text="S" className="characters">S</span>
                    <span data-preloader-text="T" className="characters">T</span>
                    <span data-preloader-text="A" className="characters">A</span>
                </div>
            </div>
        </div>
    );
};
export default PreLoader;