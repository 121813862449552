import { useEffect, useState, useCallback, useRef } from "react";
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel';
import Header from "./Header";
import Footer from "./Footer";
import { baseUrl, imgUrl } from "./Constant";
import ShopProdList from "./ShopProdList";
import { useLocation } from "react-router";
const Shop = () => {
    const [shopList, setShopList] = useState([]);
    const [shopBanner, setShopBanner] = useState([]);
    const [shopProduct, setShopProduct] = useState([]);
    const [sriptCode, setScriptCode] = useState(document.createElement('script'));
    const [item, setItemm] = useState(() => {
        const filterData = localStorage.getItem('item');
        return filterData ? JSON.parse(filterData) : { title: '', };
    })
    const shopFilterData = localStorage.getItem('item');
    const [filteredData, setFilteredData] = useState('')
    const [allProducts, setAllProducts] = useState([])
    const [allItems, setAllItems] = useState('allPro');
    const [shopId, setShopId] = useState()
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const {isFirstLoad} = location.state || {}
    useEffect(() => {
        GetProductPage(shopId)
        GetShop()
    }, [])
    const GetShop = () => {
        let url = baseUrl + `get-shop`
        axios.get(url)
            .then((resp) => {
                if (resp.status === 200) {
                    setShopList(resp.data.result)
                }
            }).catch((err) => {
                if (err.request.status === 401) {
                    console.log(err)
                }
            })
    }
    const GetProductPage = async(shop_id) => {
       await axios.post(baseUrl + `get-product`, {
            shop_id: shop_id
        })
            .then((resp) => {
                if (resp.status === 200) {
                    setShopBanner(resp.data.product_banner)
                    setAllProducts(resp.data.result)
                    resp.data.result.map((res) => {
                        if (res.shop_id === item) {
                            shopProduct.push(res)
                        }
                    })
                    setIsLoading(false) 
                    if(isFirstLoad){
                        localStorage.setItem('item' , 3/resp.data.result.length)
                    }
                }
            })
            .catch((err) => {
                if (err.request.status === 401) {
                    console.log(err)
                }
            })
    }
    return (
        <>
            <Header />
            <div className="mainContent">
                <div className="shopBannerWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <Carousel>
                                    {shopBanner.map((shopBannerSlie) => {
                                        return (
                                            <Carousel.Item>
                                                <img src={`${imgUrl}${shopBannerSlie.image_path}`} className="img-fluid" />
                                                <Carousel.Caption>
                                                    <h2>{shopBannerSlie.title}</h2>
                                                    <a href="#">Buy Now</a>
                                                </Carousel.Caption>
                                            </Carousel.Item>
                                        );
                                    })}
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="latestPostWrap shopPageProduct">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="titleWrap">
                                    <h2>Our Products</h2>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-5">
                                <ul className="filterList">
                                    <li>
                                        <button onClick={(e) => {
                                            setAllItems('allPro'); 
                                            localStorage.setItem('item', JSON.stringify(shopList.length/allProducts.length));
                                            window.location.reload()}} 
                                            className="btnCat" 
                                            style={allItems !== '' ? { backgroundColor: '#FDE8E8', color: '#161616' } : {}}>
                                                All
                                        </button>
                                    </li>
                                    {shopList.map((val, index) => {
                                        return (
                                            <li>
                                                <button onClick={(e) => { 
                                                 GetProductPage(val.id)
                                                 setAllItems('');
                                                 setFilteredData(val.id); 
                                                 setShopId(val.id)
                                                 localStorage.setItem('item', JSON.stringify(val.id)); 
                                                 window.history.replaceState({}, document.title)
                                                 window.location.reload();
                                                }} 
                                                 className="btnCat" 
                                                 style={item === val.id ? { backgroundColor: '#161616', color: '#ffffff' } : shopFilterData === val.id ? { backgroundColor: '#161616', color: '#ffffff' } : {}}>
                                                    {val.title}
                                                </button>
                                            </li>
                                        )
                                    })
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <ShopProdList
                                    shop_product={allProducts}
                                    sript_code={sriptCode}
                                    shop_item={item}
                                    is_All = {shopFilterData == shopList.length/allProducts.length ? true : false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
export default Shop;